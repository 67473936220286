<template>
  <div class="home">
    <Emoji @send="sendEmoji" />
    <Valuation />
    <LeaveMessage />
  </div>
</template>

<script>
// @ is an alias to /src
import Emoji from "@/components/Emoji";
import Valuation from "../components/Valuation.vue";
import LeaveMessage from "../components/LeaveMessage";
export default {
  name: "Home",
  components: {
    Emoji,
    Valuation,
    LeaveMessage,
  },
  methods: {
    sendEmoji(index) {},
  },
};
</script>
