<template>
  <div class="mask" v-if="evaluationList.length">
    <div class="valuation" ref="valuation">
      <div class="valuation-title" v-drag="valuation">
        对话结束，请对本次服务做出评价
      </div>
      <span class="valuation-fork">
        <a href="javascript:void(0)" @click="closeValuation(0)" />
      </span>
      <div class="assess">
        <label
          v-for="item of evaluationList"
          :key="item.id"
          @click="selectEvaluation(item)"
          :class="{ active: evaluationId === item.id }"
        >
          <input
            type="radio"
            name="drone"
            :checked="evaluationId === item.id"
          />
          <img :src="item.evaluationEmoji" alt="" />
          <span>
            {{ item.message }}
          </span>
        </label>
      </div>
      <div class="remark">
        <textarea name="remark" id="" cols="30" rows="10" v-model="remark" />
      </div>
      <div class="submit">
        <a class="submit-btn" @click="submit"> 提交评价 </a>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";

export default {
  setup() {
    let api = inject("api");
    let store = inject("store");
    let util = inject("util");

    const valuation = ref(null);
    const evaluationList = ref([]);
    const evaluationId = ref(null);
    const remark = ref("");

    // 获取评价数据
    const selectEvaluateLabel = async () => {
      let res = await api.getConversationEvaluationList();
      evaluationId.value = res.content[0].id;
      evaluationList.value = res.content;
    };
    selectEvaluateLabel();

    //选择评价
    const selectEvaluation = (item) => {
      evaluationId.value = item.id;
      remark.value = item.message;
    };
    // 关闭评价
    const closeValuation = (code) => {
      store.action.updateChatState(code);
    };

    // 提交评价
    const submit = async () => {
      let res = await api.submitConversationEvaluation({
        conversationEvaluationId: evaluationId.value,
        conversationEvaluationRemark: remark.value,
      });
      store.action.updateChatState(0);
      // closeValuation(3);
    };

    return {
      valuation,
      evaluationList,
      evaluationId,
      remark,
      selectEvaluation,
      closeValuation,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.valuation {
  width: 410px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 50px rgb(0 0 0 / 30%);

  .valuation-title {
    height: 42px;
    line-height: 42px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    background-color: #f8f8f8;
    text-overflow: ellipsis;
    cursor: all-scroll;
  }

  .valuation-fork {
    position: absolute;
    right: 15px;
    top: 15px;

    a {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      font-size: 12px;
      background: url("../assets/img/icon.png") 3px -39px;
      cursor: pointer;
    }
  }

  .assess {
    display: flex;
    float: left;
    margin-left: 20px;
    padding: 10px;

    label {
      float: left;
      width: 70px;
      height: 96px;
      flex: 1;
      cursor: pointer;
      opacity: 0.5;
    }
    .active {
      opacity: 1;
    }
    span {
      font-size: 12px;
      display: block;
      line-height: 10px;
      color: #666;
      text-align: center;
    }
  }

  .remark {
    textarea {
      width: 350px;
      height: 80px;
      resize: none;
      padding: 3px;
      border: 1px solid #eee;
      border-radius: 4px;
      margin-top: 20px;
    }
  }

  .submit {
    padding: 15px 12px;
    pointer-events: auto;

    .submit-btn {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      margin: 5px 5px 0;
      padding: 0 15px;
      border-color: #1e9fff;
      background-color: #1e9fff;
      border-radius: 2px;
      color: #fff;
      opacity: 0.9;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>
